export function jsonTryParse<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {

    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return JSON.parse(str);
    } catch (error) {

        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonEncodeToBase64String (obj: object) {
    return btoa(JSON.stringify(obj));
}

export function jsonTryDecodeBase64String<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {
    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return jsonTryParse<T>(atob(str), errorCallback)
    } catch (error) {
        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonEncodeToURIComponent (obj: object) {
    return encodeURIComponent(JSON.stringify(obj));
}

export function jsonTryDecodeURIComponent<T> (
    str: string | null | undefined,
    errorCallback?: (error: any) => void
) : T | undefined {
    if (str === null || str === undefined) {
        return undefined;
    }

    try {
        return jsonTryParse<T>(decodeURIComponent(str), errorCallback)
    } catch (error) {
        if (errorCallback) {
            try {
                errorCallback(error)
            } catch (_error) {
                // Ignore
            }
        }

        return undefined;
    }
}

export function jsonParseWithFallback<T, F> (
    str: string | null | undefined, fallbackValue: F
) : T | F {
    if (str === null || str === undefined) {
        return fallbackValue;
    }
    try {
        return JSON.parse(str);
    } catch (error) {
        return fallbackValue
    }
}

export function jsonStringify<T> (obj: T) : string {
    return JSON.stringify(obj);
}

export function jsonExtractPrimitiveLeaves(obj: unknown): (string | number | boolean | null)[] {
    const result: (string | number | boolean | null)[] = [];

    function traverse(node: unknown): void {
        if (node === null || typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean') {
            result.push(node);
        } else if (Array.isArray(node)) {
            for (const item of node) {
                traverse(item);
            }
        } else if (node !== null && typeof node === 'object') {
            for (const key in node) {
                traverse((node as Record<string, unknown>)[key]);
            }
        }
    }

    traverse(obj);
    return result;
}
